import {GTM_ID, IS_DEVELOPMENT} from '@/common/config'

export function loadScripts() {
  const searchParams = new URLSearchParams(window.location.search)
  const isStoryblok = !!searchParams.get('_storyblok')

  if (!IS_DEVELOPMENT && !isStoryblok) {
    function loadAxeptio() {
      window._axcb = window._axcb || []
      window._axcb.push((axeptio) => {
        function handleChoices(choices) {
          if (typeof choices === 'object') {
            document.querySelectorAll('[data-hide-on-vendor-consent]').forEach((el) => {
              const vendor = el.getAttribute('data-hide-on-vendor-consent')
              el.style.display = choices[vendor] ? 'none' : 'inherit'
            })
            document.querySelectorAll('[data-requires-vendor-consent]').forEach((el) => {
              const vendor = el.getAttribute('data-requires-vendor-consent')
              if (choices[vendor]) {
                el.setAttribute('src', el.getAttribute('data-src'))
              }
            })
          }
        }

        axeptio.on('cookies:complete', function (choices) {
          if (!window.axeptioIsReady) {
            window.axeptioIsReady = true
            handleChoices(choices)
          }
        })

        axeptio.on('consent:saved', function (data) {
          const choices = data?.consent?.preferences?.vendors
          handleChoices(choices)
        })
      })
    }

    function loadGTM() {
      if (GTM_ID) {
        window.dataLayer = window.dataLayer || []
        window.dataLayer.push({'gtm.start': new Date().getTime(), event: 'gtm.js'})
        const firstScriptTag = document.getElementsByTagName('script')[0]
        const googleScript = document.createElement('script')
        googleScript.async = true
        googleScript.src = `/aT8Qm5ejKk?id=${GTM_ID}`
        firstScriptTag.parentNode.insertBefore(googleScript, firstScriptTag)
      }
    }

    loadAxeptio()
    loadGTM()
  }
}
