/* eslint-disable no-warning-comments */
import './src/styles/globals.scss'

import gsap from 'gsap'
import {ScrollTrigger} from 'gsap/ScrollTrigger'
import * as React from 'react'

import {loadScripts} from '@/common/scripts'

export const onRouteUpdate = () => {
  window.dispatchEvent(new Event('onChangeRoute'))
}

export const wrapPageElement = ({element}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  React.useEffect(() => {
    loadScripts()
    gsap.registerPlugin(ScrollTrigger)
    ScrollTrigger.normalizeScroll({
      allowNestedScroll: true,
    })
  }, [])

  return element
}
